// src/pages/NewPage.js

import React from 'react';

function NewPage() {
  return (
    <div className="new-page">
      <h1>New Page</h1>
      <p>This is the new page.</p>
    </div>
  );
}

export default NewPage;
